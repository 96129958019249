<template>
	<div class="container">
		<!-- <div style="position: absolute;top: 0;z-index: ;width: 100%;background: linear-gradient(to top,rgba(0,0,0,0),rgba(0,0,0,0));">
      <img  src="https://oss.culturalcloud.net/furong/202212/01090934cyy6.jpg" style="width: 100%;;height: 100%;," alt="">
    </div> -->

		<div class="w">
			<div style="width: 1000px; margin: 50px auto">

				<el-input v-model="search" placeholder="请输入要搜索的书名" size="normal" clearable @change="searchBook">
					<el-button slot="append" icon="el-icon-search">搜索一下</el-button>
				</el-input>
			</div>
			<el-row :gutter="20">
				<el-col :span="6" :offset="0" style="position: sticky;top:80px">
					<el-card class="sort" :body-style="{padding:0}">
						<div slot="header" class="clearfix" >
							<span class="header-title">图书分类</span>
						</div>
						<div style="" class="haha">
						<div  v-for="(item,index) in bookType" @click="toType(item.dictValue,index)" :key="item.dictCode"
							class="text  item" :class="type===index?'active':''">
							<!-- <div v-for="(item,index) in bookType"
							@click="$router.push({query: {type:item.dictLabel}})" :key="item.dictCode"
							class="text item" :class="type===index?'active':''"> -->
							<template v-if="item.dictLabel=='全部'">
								<span  style="margin:auto">{{item.dictLabel}}</span>
							</template>
							<template v-else>
								<span class="type-info">{{item.dictLabel.split(' ')[0]}}</span>
								<span class="info">{{item.dictLabel.split(' ')[1]}}</span>
							</template>
							
							
						</div>
					</div>
					</el-card>
				</el-col>
				<el-col :span="18" :offset="0">
					<el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-loading="loading">
						<div slot="header" class="clearfix">
							<span class="title"  style="font-size: 20px;">图书列表</span>
						</div>
						<div class="book-container" v-if="bookList.length != 0">
							<div v-for="item in bookList" :key="item.bookId" class="book-list">
								<div class="book-content">
										<div   class="img" @click="$router.push({ path: '/detail/'+item.bookId })">
											<el-popover
										placement="right"
										title="扫码看书"
										trigger="hover"
										>
										<vue-qr  :text="`${url}/phone?id=${item.bookId}&chapter=${item.firstChapterId}&name=${item.title}`" :size="200"></vue-qr>
											
											<img slot="reference" :src="item.titleImg" style="height: 100%" alt="" />
							
									</el-popover>
											<!-- <img :src="item.titleImg" style="height: 100%" alt="" /> -->
										</div>
									
									<div class="book-info">
										<p class="head">
											<span class="title es-1"
												@click="$router.push({ path: '/detail/'+item.bookId })">{{item.title}}</span>
										
										</p>
										<span class="author"><i class="el-icon-user" />{{item.author}}</span>
										<p class="publish">出版社:{{item.publish?item.publish:'暂无'}}</p>
										<!-- <el-divider></el-divider> -->
										<p class="es-2 content">
											作品简介:{{item.description}}
										</p>
										<div class="view">
											<div>
												<span>阅读量 <span class="num">{{(item.view+1)}}</span></span>
												<span style="margin-left: 30px">收藏量 <span
														class="num">{{item.collection}}</span></span>
											</div>
											
										</div>
										<div style="margin-top: 30px;">
												<el-button style="width: 100%;" type="primary" icon="el-icon-reading" round
													@click="$router.push({ path: '/detail/'+item.bookId })">开始阅读
												</el-button>
											</div>
									</div>
								</div>
								<!-- <el-divider direction="horizontal" content-position="left"></el-divider> -->
							</div>
						</div>
						<div v-else style="margin: 0 auto;">
							<el-empty description="暂未更多内容"></el-empty>
						</div>
					</el-card>
					<div style="text-align: center;">
						<el-button type="primary" size="default" :icon="more?'el-icon-arrow-down':'el-icon-close'"
							:disabled="!more" @click="nextPage">{{more?'下一页':'没有更多了'}}</el-button>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'
import vueQr from 'vue-qr'
	import {
		getBookList,
		getType,
		addCollect
	} from "@/api";
	export default {
		name: "list",
		components: {
				vueQr
		},
		data() {
			return {
				url:location.origin,
				// url:'阿斯顿',
				search: "",
				select: '',
				bookList: [],
				bookType: [],
				type: 0,
				page: 1,
				loading: true,
				more: true
			};
		},
		created() {
			this.getBookType()
		},
		methods: {
			nextPage() {
				this.page++;
				// if (this.$route.query.type) {
				// 	this.getBook(this.$route.query.type, this.page)
				// } else {
					console.log(this.bookType[this.type].dictValue);
					this.getBook(this.bookType[this.type].dictLabel == '全部' ? '' : this.bookType[this.type].dictValue, this
						.page)
				// }

			},
			toType(type, index) {
				if (this.type === index) return false
				scrollTo(150,300)
				// console.log(type);
				this.type = index
				this.page = 1
				this.bookList = []
				this.loading = true
				setTimeout(() => {
					this.getBook(type == '全部' ? '' : type)
				}, 500)
			},
			getBookType() {
				getType().then(res => {
					if (res.code === 200) {
						let arr = res.data
						arr.unshift({
							dictLabel: '全部'
						})
						if (this.$route.query.type) {
							this.type = arr.findIndex((item) => item.dictLabel == this.$route.query.type)
						}
						this.bookType = arr
						this.getBook(this.bookType[this.type].dictValue);
					}
				})
			},
			getBook(type = "", page = 1, title = '') {
				// this.loading=true
				getBookList({
					pageNum: page,
					pageSize: 10,
					type,
					title,
					reasonable:false
				}).then(res => {
					this.loading = false
					if (res.code === 200) {
						// this.bookList=res.rows
						this.bookList = this.bookList.concat(res.rows);
						if (res.rows.length < 10) {
							// 没有更多了
							this.more = false;
						} else {
							// 还有
							this.more = true;
						}

					}
				})
			},
			searchBook(e) {
				console.log(e);
				this.type = 0
				this.bookList = []
				this.loading = true
				setTimeout(() => {
					this.getBook(this.pageNum, '', e)
				}, 500)

			},
			collcet(id) {
				if (!this.Storage.get('token')) {
					this.$message.warning({
						message: '您还没有登录，去登录!'
					})
					setTimeout(() => {
						this.$router.push({
							path: '/login'
						})
					}, 500)
				} else {
					// console.log(id)
					addCollect(id).then(res => {
						this.$message.success({
							message: '收藏成功！'
						})
					})
					// console.log(2);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.haha {
		height: 700px;overflow-y: scroll;
	}
	.haha::-webkit-scrollbar{
		height: 0 !important;
		width: 0 !important;
	}
	::v-deep .el-input .el-input--suffix {
		width: 200px;
	}

	.el-select .el-input {
		width: 130px;
	}

	.container {
		background-color: #f6f4ec;
		position: relative;
		// background-image: linear-gradient(to top,
		//     rgba(255, 255, 255, 1),
		//     rgba(255, 255, 255, 0)),
		//   url("https://oss.culturalcloud.net/furong/202212/01090934cyy6.jpg");
		//   // width: 100%;
		//   // height: 1000px;
		// background-size: contain;
	}

	.book-container {
		display: flex;
		justify-content: space-between;

		flex-wrap: wrap;
		// border: 1px solid;

		.book-list {
			width: 48%;
			// height: 300px;
			display: flex;
			flex-direction: column;

			.book-content {
				display: flex;
				justify-content: space-between;
				height: 300px;

				&:nth-last-child(1) {
					margin-bottom: 0;
				}

				.img {
					width: 40%;
					height: 250px;
					overflow: hidden;
					box-shadow: 0 0 10px rgba($color: #000000, $alpha: .1);

					img {
						width: 100%;
						height: 100%;
					}

					// height: 200px;
				}

				.book-info {
					width: 55%;
					.author {
							margin-top: 30px;
							// margin-left: 30px;
						}
					.head {
						display: flex;
						margin-bottom: 10px;

						.title {
							// height: 50px;
							cursor: pointer;
							width: 300px;
							font-size: 20px;
						}

						
					}

					.publish {
						margin-top: 10px;
						color: #999;
						font-size: 14px;
					}

					.content {
						color: #999;
						font-size: 14px;
					}

					.view {
						color: #777;
						font-size: 14px;
						margin-top: 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.num {
							color: red;
						}
					}
				}
			}

			// margin-bottom: 30px;
			// border: 1px solid #ccc;
		}
	}

	.text {
		// margin-left: 20px;
		font-size: 14px;
		text-align: center;
		line-height: 30px;
		display: flex;
		// justify-content:space-between;
		align-items: center;
		padding: 10px;
		.type-info {
			border: 1px solid #fff;
			// width:20%;
			width: 30px;
			// text-align: center;
			background-color:#6CB0F3;
			color: #fff;
			

		}
		.info {
			// width: 75%;
			margin-left: 12px;
			text-align: center;
		}

		&:hover {
			color: #fff;
			background-color: #6CB0F3;
		}
		&:hover .type-info {
			color: #000;
			background-color: #fff;
		}
	}

	.active {
		font-weight: bold;
		color: #fff;
		background-color: #6CB0F3;
		text-decoration: underline;
	}

	.item {
		cursor: pointer;
		margin-bottom: 10px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: "";
	}

	.clearfix:after {
		clear: both;
	}

	::v-deep .sort {
		position: sticky;
		top: 30px;

		.el-card__header {
			// background-color: #6cb0f3;
		}

		.header-title {
			// text-align: center;
			// color: #fff;
			font-size: 20px;
			// font-weight: bold;
		}
	}
</style>
